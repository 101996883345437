export const handleGoToCart =
  (cartUrl: string, isCartPageOpenInNewTab: boolean) =>
  (e: React.MouseEvent) => {
    e.preventDefault();
    if (isCartPageOpenInNewTab) {
      window.parent.open(cartUrl, '_blank');
    } else {
      window.parent.location.href = cartUrl;
    }
  };

export const handleKeepBuying = (e: React.MouseEvent) => {
  e.preventDefault();
  window.parent.location.reload();
};
