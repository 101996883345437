import { ADD_TO_CART_MODAL_DATA } from '../../utils/constants';
import { IMAGE_URLS } from '../../utils/constants/urls';
import { handleGoToCart, handleKeepBuying } from '../../utils/cartUtils';
import {
  Backdrop,
  GoToCartButton,
  KeepBuyingButton,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTitleIcon,
} from './styles';

const AddToCartModal: React.FC<{
  cartUrl: string;
  isCartPageOpenInNewTab: boolean;
}> = ({ cartUrl, isCartPageOpenInNewTab }) => {
  const { SUCCESS_ICON } = IMAGE_URLS;
  const { SUCCESS_TITLE, KEEP_BUYING_BUTTON_TEXT, GO_TO_CART_BUTTON_TEXT } =
    ADD_TO_CART_MODAL_DATA;

  return (
    <Backdrop data-testid="addtocart-model">
      <Modal>
        <ModalHeader>
          <ModalTitleIcon src={SUCCESS_ICON.URL} alt={SUCCESS_ICON.ALT_TEXT} />
          <ModalTitle>{SUCCESS_TITLE}</ModalTitle>
        </ModalHeader>
        <ModalFooter>
          <KeepBuyingButton onClick={handleKeepBuying} tabIndex={0}>
            {KEEP_BUYING_BUTTON_TEXT}
          </KeepBuyingButton>
          <GoToCartButton
            onClick={handleGoToCart(cartUrl, isCartPageOpenInNewTab)}
            tabIndex={0}
          >
            {GO_TO_CART_BUTTON_TEXT}
          </GoToCartButton>
        </ModalFooter>
      </Modal>
    </Backdrop>
  );
};

export default AddToCartModal;
